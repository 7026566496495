import React, { useState } from 'react'
import './navbar.css'
import logo from './logo.png'
import { useNavigate, useLocation } from 'react-router-dom';
import { SideBar } from './SideBar/sideBar';
import { IoIosMenu } from "react-icons/io";

export const Navbar = () => {

    const [sideBarActive, setSideBarActive] = useState(false);
    const isMobileScreen = window.innerWidth <= 1000 ? true : false;
    const navigate = useNavigate();
    const location = useLocation();
    const pathName = location.pathname;
    return (
        <>
            <div className='navbarContainer'>
                {isMobileScreen ? <></> : <h2 onClick={() => { navigate('/') }} style={{ cursor: 'pointer', width: '8rem' }}>FlexFooty</h2>}
                <div style={{ minWidth: '6rem', display: 'flex' }}>
                    <img 
                    onClick={() => { navigate('/') }}
                    src={logo} style={{ width: isMobileScreen ? '42px' : '48px', height: isMobileScreen ? '42px' : '48px', cursor: 'pointer' }} />
                </div>
                {
                    isMobileScreen
                        ?
                        <IoIosMenu size={32} onClick={() => { setSideBarActive(true) }} />
                        :
                        <button className='primaryButton navbarButton' onClick={() => { pathName === "/contactUs" ? navigate('/registerteam') : navigate('/contactUs') }}>{pathName === "/contactUs" ? 'Register' : 'Contact Us'}</button>
                }

            </div>
            {isMobileScreen ? <SideBar data={[sideBarActive, setSideBarActive]} /> : <></>}
        </>
    )
}
