import React from 'react'
import './footer.css'
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";

export const Footer = () => {
    return (
        <div className='footerContainer'>
            <h2 onClick={()=>{window.scrollTo(0,0)}}>FlexFooty</h2>
            <p>All rights reserved.</p>
            <div className='footerIconsContainer'>
                <FaTwitter size={18} fill='#f9f6eecc' style={{ cursor: 'pointer' }} />
                <AiFillInstagram size={18} fill='#f9f6eecc' style={{ cursor: 'pointer' }} />
                <FaFacebook size={18} fill='#f9f6eecc' style={{ cursor: 'pointer' }} />
            </div>
        </div>
    )
}
