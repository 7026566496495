import React from 'react'
import '../PointsTable/pointsTable.css'
import { fetchTeams } from '../../../../utils/getDataForDashboard'
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

export const Teams = () => {

    const { data: teams, isLoading, error } = useQuery({ queryKey: ["Teams"], queryFn: fetchTeams });
    if (isLoading) return <p>Loading Fixtures</p>
    // loading component to be included
    if (error) return toast.error(error.message);

    return (
        <div className='pointsTableScreen'>
            <div className='pointsTableContainer'>
                <header>
                    <p style={{ minWidth: '25%', textAlign: 'left' }}>Team</p>
                    <p style={{ minWidth: '22%', textAlign: 'left' }}>Manager</p>
                    <p style={{ minWidth: '22%', textAlign: 'left' }}>Manager's Email</p>
                    <p style={{ minWidth: '22%', textAlign: 'left' }}>Contact Number</p>
                </header>
                {
                    teams.map((team, index) => (
                        <div key={index} className='pointsTableRow'>
                            <p style={{ minWidth: '25%', textAlign: 'left' }}>{team.team_name}</p>
                            <p style={{ minWidth: '22%', textAlign: 'left' }}>{team.manager_name}</p>
                            <p style={{ minWidth: '22%', textAlign: 'left' }}>{team.manager_email}</p>
                            <p style={{ minWidth: '22%', textAlign: 'left' }}>{team.manager_contact_number}</p>
                            <p style={{cursor:'pointer',fontFamily:'CustomFont, sans-serif', textDecoration:'underline'}}>Edit</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
