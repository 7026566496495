import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import {fetchFixtures} from '../../../../utils/getDataForDashboard'
import '../internalComponents.css'
import toast from 'react-hot-toast';
import { FaPlus } from "react-icons/fa";
import { EditFixture } from './editFixture';
import { CreateFixture } from './createFixture';

export const Fixtures = () => {

    const [updateFixture, setUpdateFixture] = useState(null);
    const [createFixture, setCreateFixture] = useState(false);
    const { data: fixtures, isLoading, error } = useQuery({ queryKey: ["fixtures"], queryFn: fetchFixtures });

    if (isLoading) return <p>Loading Fixtures</p>
    // loading component to be included
    if (error) return toast.error(error.message);

    const handleEditButton = (fixture) => {
        setUpdateFixture(fixture);
    }

    const isScreenWidthSmall=window.innerWidth<=1380?true:false;


    return (
        <div className='fixturesContainer'>
            <div style={{ width:isScreenWidthSmall?'90%':'85%', display: 'flex' }}>
                <button className='primaryButton createFixtureButton' 
                        onClick={()=>{setCreateFixture(true)}}
                >
                    <FaPlus size={12} style={{ marginRight: '0.5rem' }} />
                    Create Fixture
                </button>
            </div>
            {fixtures.map((fixture) => (
                <div key={fixture.match_id} className='fixtureCard'>
                    <p>{fixture.match_date.split('-').reverse().join('-')}</p>
                    <p>{fixture.status ? 'FT' : 'TBP'}</p>
                    <p>{fixture.match_time}</p>
                    <p style={{ minWidth: '6rem' }}>{fixture.teama}</p>
                    <p style={{ minWidth: '4.5rem', backgroundColor: '#71797e32', textAlign: 'center', fontFamily: 'CustomFont, sans-serif', padding: '0.3rem 0', borderRadius: '0.7rem', marginRight: '0.7rem' }}>
                        {fixture.teama_goal} - {fixture.teamb_goal}
                    </p>
                    <p>{fixture.teamb}</p>
                    <button className='primaryButton adminEditButton'
                        onClick={() => { handleEditButton(fixture) }}
                    >
                        Edit
                    </button>
                </div>
            ))}

            {/* adding edit fixture component here, will be replaced with its own component*/}
            {
                updateFixture != null ?
                    <EditFixture data={[updateFixture, setUpdateFixture]} />
                    :
                    createFixture ?
                        <CreateFixture data={setCreateFixture}/>
                        :
                        <></>
            }
        </div>
    )
}
