import { React, useEffect, useState } from 'react'
import { Navbar } from '../../components/Navbar/navbar'
import { Footer } from '../../components/Footer/footer'
import {CustomBarLoader} from '../../components/loader/loader';

import './rules.css';

export const Rules = () => {

    const [rules, setRules] = useState([]);
    useEffect(() => {
        const getRules = async (selectedRules) => {
            const params = new URLSearchParams();
            params.append("ids", selectedRules);
            const response = await fetch(process.env.REACT_APP_ENV_URL + `/api/admin/getRules`, {
                method: 'GET',
                header: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            console.log(response);
            const res = await response.json();
            if (response.status == 200) {
                setRules(res.data);
            } else {
                setRules([]);
            }


        }
        getRules();
    }, [])

    return (
        <>
            {
                rules.length === 0 ?
                    <CustomBarLoader />
                    :
                    <div>
                        <Navbar />
                        <div className='rulesContainer'>
                            <div className='rulesListContainer'>
                                <p className='RuleHeading'>Rules</p>
                                {rules.map((item, index) => (
                                    <p key={index} className='RuleDesc'>{index + 1}. {item.rule_desc}</p>
                                ))}
                            </div>
                        </div>
                        <Footer />
                    </div>
            }
        </>

    )
}
