
export const fetchFixtures = async () => {
    const response = await fetch(process.env.REACT_APP_ENV_URL + "/api/admin/getFixtures", {
        method: "GET",
        headers: { "content-type": "Application/json" },
        credentials: 'include'
    });
    if (response.status == 200) {
        const res = await response.json();
        return res.data;
    } else {
        return [];
    }
}

export const fetchPointsTable = async () => {
    const response = await fetch(process.env.REACT_APP_ENV_URL + "/api/admin/getPointsTabledata", {
        method: "GET",
        headers: { "content-type": "Application/json" },
        credentials: 'include'
    });
    if (response.status == 200) {
        const res = await response.json();
        return res.data;
    } else {
        return [];
    }
}

// have to optimize this call
export const fetchTeams = async () => {
    const response = await fetch(process.env.REACT_APP_ENV_URL + "/api/admin/getTeams", {
        method: "GET",
        headers: { "content-type": "Application/json" },
        credentials: 'include'
    });
    console.log(response);
    if (response.status == 200) {
        const res = await response.json();
        console.log(res.data);
        return res.data;
    } else {
        return [];
    }
}

export const fetchVenues = async () => {
    const response = await fetch(process.env.REACT_APP_ENV_URL + "/api/admin/getVenues", {
        method: "GET",
        headers: { "content-type": "Application/json" },
        credentials: 'include'
    });
    console.log(response);
    if (response.status == 200) {
        const res = await response.json();
        console.log(res.data);
        return res.data;
    } else {
        return [];
    }
}

export const fetchDivisions = async () => {
    const response = await fetch(process.env.REACT_APP_ENV_URL + "/api/admin/getdivisions", {
        method: "GET",
        headers: { "content-type": "Application/json" },
        credentials: 'include'
    });
    console.log(response);
    if (response.status == 200) {
        const res = await response.json();
        console.log(res.data);
        return res.data;
    } else {
        return [];
    }
}


