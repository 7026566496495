import React from 'react'
import './slider.css';
import { useNavigate } from 'react-router-dom';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';


export const Slider = () => {

    const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay({
        stopOnInteraction: false
    })]);
    const navigate = useNavigate();
    const tournamentData = [
        {
            name: 'FC Cup',
            date: '11/04/2025',
            image: 'https://images.pexels.com/photos/1302514/pexels-photo-1302514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
        {
            name: 'The Soccer Cup',
            date: '12/25/2025',
            image: 'https://images.pexels.com/photos/4056597/pexels-photo-4056597.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
        {
            name: 'Under 15 league',
            date: '01/10/2025',
            image: 'https://images.pexels.com/photos/1171084/pexels-photo-1171084.jpeg'
        },

    ]

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })
    }
    return (
        <div className='sliderContainer'>
            <div className='embla' ref={emblaRef}>
                <div className='embla__container'>
                    {tournamentData.map((item, index) => (
                        <div className='embla__slide' key={index} style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),url(${item.image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                            <h1>{item.name}</h1>
                            <div>
                                <h3>Starts</h3>
                                <h3>{formatDate(item.date)}</h3>
                            </div>
                            <button className='primaryButton' onClick={() => { navigate('/registerteam') }}>Register</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
