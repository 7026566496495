import React from 'react'
import { SideNavBar } from '../../components/dashboard/sideNavBar'
import { InfoElements } from '../../components/dashboard/infoElements'

const Dashboard = () => {
    return (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
            <SideNavBar />
            <InfoElements />
        </div>
    )
}

export default Dashboard