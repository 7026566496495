import React, { useState } from 'react'
import './dashboard.css'
import { useAuthContext } from '../../context/authContext';
import {useNavigate} from 'react-router-dom';

//importing icons
import { TbTournament } from "react-icons/tb";
import { GiBabyfootPlayers, GiSoccerField } from "react-icons/gi";
import { CiViewTable, CiLogout } from "react-icons/ci";
import { SiPrivatedivision } from "react-icons/si";


export const SideNavBar = () => {

  const { currentAdminMenu, setCurrentAdminMenu } = useAuthContext();
  const navigate=useNavigate();
  const handleSignOut=()=>{
    navigate('/')
  }

  return (
    <div className='MenuContainer'>
      <div className='listContainer'>
        <button className={`menuButton ${currentAdminMenu==null?'highlight':''}`} onClick={() => { setCurrentAdminMenu(null) }}>
          <TbTournament size={14}/>
          <p>Fixtures</p>
        </button>
        <button className={`menuButton ${currentAdminMenu=='PointsTable'?'highlight':''}`} onClick={() => { setCurrentAdminMenu('PointsTable') }}>
          <CiViewTable size={16} />
          <p>Points Table</p>
        </button>
        <button className={`menuButton ${currentAdminMenu=='Teams'?'highlight':''}`} onClick={() => { setCurrentAdminMenu('Teams') }}>
          <GiBabyfootPlayers size={14} />
          <p>Teams</p>
        </button>
        <button className={`menuButton ${currentAdminMenu=='Venues'?'highlight':''}`} onClick={() => { setCurrentAdminMenu('Venues') }}>
          <GiSoccerField size={14} />
          <p>Venues</p>
        </button>
        <button className={`menuButton ${currentAdminMenu=='Divisions'?'highlight':''}`} onClick={() => { setCurrentAdminMenu('Divisions') }}>
          <SiPrivatedivision size={14} />
          <p>Divisions</p>
        </button>
      </div>

      <button className='menuButton signOutButton' onClick={handleSignOut}>
        <CiLogout style={{ marginRight: '0.5rem' }} />
        Sign Out
      </button>
    </div>
  )
} 
