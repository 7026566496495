import React, { useState } from 'react'
import './oneTimeBanner.css'
import { IoClose } from "react-icons/io5";
import toast from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';

export const OneTimeBanner = (props) => {
    let setResponded = props.data;
    const [interested, setInterested] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [age, setAge] = useState("");

    const handleSubmit = async () => {
        //input checks
        const emailRegex=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(email.length==0){
            toast.error("Email cannot be empty");
            return;
        }
        if(!emailRegex.test(email)){
            toast.error("Not a valid email");
            return;
        }
        if(age.length===0){
            toast.error("Age cannot be empty");
            return;
        }
        if ((/^\d+$/.test(age)===false))
        {
            toast.error("Age format not valid");
            return;
        }
        if(Number(age)>100){
            toast.error("Are you sure you are over 100..?");
            return;
        }

        //input checks
        setLoading(true);
        let body = {
            email: email,
            age: age
        }
        const response =await fetch(process.env.REACT_APP_ENV_URL + '/api/user/recordInterested', {
            method: 'POST',
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(body),
            credentials: 'include'
        });

        if (response.status === 200) {
            toast.success("Thank you for your interest");
            setLoading(false);
            setTimeout(()=>{
                setResponded(true);
            },2000)
            //add service to email them
        } else {
            setLoading(false);
            toast.error("This email Id has already shown interest");
        }
    }

    return (
        <div className='bannerContainer'>
            <div className='sponsorsTab'>
                <p></p>
                <p></p>
            </div>
            <h1>
                Doon Football League
            </h1>
            <p style={{ marginTop: '1.5rem' }}>Feburary, 2025</p>
            <div className='bannerButtonContainer'>
                <button className='secondaryButton' onClick={() => { setResponded(true) }}>Show more</button>
                <button className='primaryButton' onClick={() => { setInterested(true) }}>Interested</button>
            </div>
            {
                interested ?
                    <div className='modal'>
                        <IoClose size={22} style={{ position: 'absolute', top: '10', right: '10', cursor: 'pointer' }} onClick={() => { setInterested(false) }} />
                        <div className='input_wrapper'>
                            <p>Email Address</p>
                            <input type="email" placeholder='' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </div>
                        <div className='input_wrapper' style={{ marginTop: '0' }}>
                            <p>Your Age</p>
                            <input type="text" placeholder='' value={age} onChange={(e) => { setAge(e.target.value) }} />
                        </div>
                        <button className='primaryButton' style={{ marginTop: '1rem' }} onClick={handleSubmit}>
                            {!loading ?
                                'Submit'
                                :
                                <ClipLoader color='#fff' size={18} speedMultiplier={0.7} />
                            }
                        </button>
                        <p>
                            We collect your email during the tournament sign-up to keep you informed about important updates,
                            match schedules, and event changes. Rest assured, we respect your privacy and won’t flood your inbox with promotional emails.
                            You'll only receive essential information related to this league.
                        </p>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}
