import React, { useState } from 'react'
import './faqs.css'
import { Navbar } from '../../components/Navbar/navbar';
import { useQuery } from '@tanstack/react-query';
import { getFAQs } from '../../utils/helperFunctions';
import { Footer } from '../../components/Footer/footer';
import {toast} from 'react-hot-toast';
import { CustomBarLoader } from '../../components/loader/loader';

export const Faqs = () => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const toggleView = (index) => {
        if (index === activeIndex) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(index);
        }
    }
    const { data: FAQs, isLoading, error } = useQuery({ queryKey: ["FAQS"], queryFn: getFAQs });
    if (isLoading) return <CustomBarLoader/>
    
    if (error) return toast.error(error.message);
   
    return (
        <div>
            <Navbar />
            <div className='faqContainer'>
                {
                    FAQs.map((item, index) => (
                        <div style={{ width: '90%' }} key={index} onClick={() => { toggleView(index) }}>
                            <div className='question'>
                                <p>{item.question}</p>
                                <svg width="12" height="10" viewBox='0 0 42 25' className={`${activeIndex === index ? 'activeSVG' : ''}`}>
                                    <path d="M3 3L21 21L39 3" stroke="white" strokeWidth="4" strokeLinecap="round" />
                                </svg>
                            </div>
                            <div className={`answer ${activeIndex == index ? 'active' : ''}`}>
                                <p>
                                    {item.answer}
                                </p>
                            </div>
                            <div className='line'>
                            </div>
                        </div>
                    ))
                }
            </div>
            <Footer />
        </div>
    )
}
